import LinkAndLink from "../Components/LinkAndLink";
import Mail from "../Components/Mail";

const Eula = () => {
	document.title = "EULA Playdarium"

	return <div>
		<header className="App-text">
			<h1>End-User License Agreement (EULA)</h1>
			<br/>
			<h2>Agreement to Terms</h2>
			<p className="text-privacy">By installing and using our application you agree to be bound by these Terms. You must
				not install and use the
				application if you do not agree with these Terms.</p>
			<p className="text-privacy">This End-User License Agreement ("EULA") is a legal agreement between you and Mark
				Iakobson (“Developer”)</p>
			<p className="text-privacy">This EULA agreement governs your acquisition and use of our the App directly from
				Developer or indirectly through
				a Developer authorized reseller or distributor (a "Reseller").</p>
			<p className="text-privacy">Please read this EULA agreement carefully before completing the installation process
				and
				using the App. It
				provides a license to use the App and contains warranty information and liability disclaimers.</p>
			<p className="text-privacy">If you register for a free trial of the App, this EULA agreement will also govern that
				trial. By clicking
				"accept" or installing and/or using the App, you are confirming your acceptance of the App and agreeing to
				become
				bound by the terms of this EULA agreement.</p>
			<p className="text-privacy">If you are entering into this EULA agreement on behalf of a company or other legal
				entity, you represent that you
				have the authority to bind such entity and its affiliates to these terms and conditions. If you do not have such
				authority or if you do not agree with the terms and conditions of this EULA agreement, do not install or use the
				App, and you must not accept this EULA agreement.</p>
			<p className="text-privacy">This EULA agreement shall apply only to the App supplied by Developer herewith
				regardless of whether other app is
				referred to or described herein. The terms also apply to any Developer updates, supplements, Internet-based
				services, and support services for the App, unless other terms accompany those items on delivery. If so, those
				terms apply.</p>
			<p className="text-privacy">We reserve the right to make changes to these Terms. We will publish a notice in case
				changes to the Terms.</p>
			<br/>
			<h2>License Grant</h2>
			<p className="text-privacy">Developer hereby grants you a personal, non-transferable, non-exclusive licence to use
				the App on your devices in accordance with the terms of this EULA agreement.</p>
			<p className="text-privacy">You are permitted to load the App (for example a PC, laptop, mobile or tablet) under
				your control. You are responsible for ensuring your device meets the minimum requirements of the App.</p>
			<p className="text-privacy">You are not permitted to:</p>
			<p className="text-privacy">
				{'\u2022'} Edit, alter, modify, adapt, translate or otherwise change the whole or any part of the Software nor
				permit the whole or any part of the Software to be combined with or become incorporated in any other software,
				nor
				decompile, disassemble or reverse engineer the Software or attempt to do any such things{<br/>}
				{'\u2022'} Reproduce, copy, distribute, resell or otherwise use the App for any commercial purpose{<br/>}
				{'\u2022'} Allow any third party to use the App on behalf of or for the benefit of any third party{<br/>}
				{'\u2022'} Use the App in any way which breaches any applicable local, national or international law{<br/>}
				{'\u2022'} use the Software for any purpose that Developer considers is a breach of this EULA agreement
			</p>
			<br/>
			<h2>Intellectual Property and Ownership</h2>
			<p className="text-privacy">Developer shall at all times retain ownership of the App as originally downloaded by
				you
				and all subsequent downloads of the App by you. the App (and the copyright, and other intellectual property
				rights
				of whatever nature in the App, including any modifications made thereto) are and shall remain the property of
				Developer.
			</p>
			<p className="text-privacy">Developer reserves the right to grant licences to use the App to third parties.</p>
			<br/>
			<h2>Termination</h2>
			<p className="text-privacy">This EULA agreement is effective from the date you first use the App and shall
				continue
				until terminated. You may terminate it at any time upon written notice to Developer.</p>
			<p className="text-privacy">It will also terminate immediately if you fail to comply with any term of this EULA
				agreement. Upon such termination, the licenses granted by this EULA agreement will immediately terminate and you
				agree to stop all access and use of the App. The provisions that by their nature continue and survive will
				survive
				any termination of this EULA agreement.</p>
			<br/>
			<h2>Disclaimer of Warranties</h2>
			<p className="text-privacy">WITHOUT LIMITING DEVELOPER'S LIABILITY THE SERVICE IS PROVIDED ON AN "AS IS" AND "AS
				AVAILABLE" BASIS FOR YOUR USE, WITHOUT WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION
				THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, AND THOSE ARISING
				FROM COURSE OF DEALING OR USAGE OF TRADE. DEVELOPER DOES NOT WARRANT THAT YOU WILL BE ABLE TO ACCESS OR USE THE
				SERVICE AT THE TIMES OR LOCATIONS OF YOUR CHOOSING; THAT THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE; THAT
				DEFECTS WILL BE CORRECTED; OR THAT THE GAME OR THE SERVICE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.</p>
			<p className="text-privacy">Some jurisdictions do not allow the exclusion of certain warranties. Accordingly, some
				of the above disclaimers may not apply to you.</p>
			<br/>
			<h2>Governing Law</h2>
			<p className="text-privacy">If a dispute arises between you and Developer, we strongly encourage you to first
				contact us directly to seek a resolution by going to our customer support at email {<Mail
					mail='playdarium@gmail.com'/>}.</p>
			<p className="text-privacy">This EULA agreement, and any dispute arising out of or in connection with this EULA
				agreement, shall be governed by and construed in accordance with the laws of Hungary.</p>
			<br/>
			<h2>Force Majeure</h2>
			<p className="text-privacy">Developer shall not be liable for any delay or failure to perform resulting from
				causes
				outside the reasonable control of Developer, including without limitation any failure to perform hereunder due
				to
				unforeseen circumstances or cause beyond Developer’s control such as acts of God, war, terrorism, riots,
				embargoes, acts of civil or military authorities, fire, floods, accidents, strikes, or shortages of
				transportation
				facilities, fuel, energy, labor or materials.</p>
			<br/>
			<h2>Contacts</h2>
			<text className="text-privacy">
				If you have any questions please contact us by email or postal mail:{<br/>}
				{<Mail mail='games@playdarium.com'/>}{<br/>}
				Garay street 22 apt 3, 1076{<br/>}
				Budapest, Hungary
			</text>
			<br/>
			<br/>
			<LinkAndLink name1='Contact Us' uri1='/contacts' name2='Privacy Policy' uri2='/privacy'/>
			<br/>
		</header>
	</div>
}

export default Eula