import PropTypes from "prop-types";

const Mail = (props) => {
	return <a className="App-link" href={"mailto:" + props.mail} target="_blank">
		{props.mail}
	</a>
}

Mail.propTypes = {
	mail: PropTypes.string.isRequired
}

export default Mail