import LinkAndLink from "../Components/LinkAndLink";

const PrivacyPolicy = () => {
	document.title = "Playdarium Privacy"

	return <div>
		<header className="App-text">
			<h2>Thank you for playing our games!</h2>
			<p className="text-privacy">
				This privacy policy describes:{<br/>}
				How applications hosted on a developer’s account “Playdarium” use personal information. We refer to users of
				mobile applications as users of mobile application services (we here collectively call the Services). Using any
				of the mobile applications, you accept and agree to the practice described in the Privacy Policy.{<br/>}
				We may periodically update this Privacy Policy.
			</p>
			<br/>
			<h2>Contact us</h2>
			<p className="text-privacy">
				If you have questions about data protection, or if you have any requests for resolving issues with your personal
				data, we encourage you to primarily contact us:
			</p>
			<p>Send us an email: {
				<a
					className="App-link"
					href="mailto:games@playdarium.com"
					target="_blank"
				>
					games@playdarium.com
				</a>
			}</p>
			<br/>
			<h2>The Data we collect:</h2>
			<p className="text-privacy">
				{<b>Data you provide us</b>}{<br/>}
				{'\u2022'} Player name and password{<br/>}
				{'\u2022'} Contact information (such as name and email address){<br/>}
				{'\u2022'} Profile information (such as profile photo){<br/>}
				{'\u2022'} Other data you choose to give us (such as data to identify a lost account)
			</p>
			<p className="text-privacy">
				{<b>Data we collect automatically</b>}{<br/>}
				{'\u2022'} Information about the use of our services.{<br/>}
				{'\u2022'} Device Information and non-identifiable information (your IP address, unique device ID, anonymous
				advertising identifiers, operating system, mobile network information, existing applications, browsing
				activity).{<br/>}
				{'\u2022'} General location data{<br/>}
				{'\u2022'} Precise geo-location data (GPS, with your consent, for the provision of advanced location-based
				services, as well as for location-oriented advertising){<br/>}
				{'\u2022'} Mobile Applications uses a number of cookies to collect non-personal information for many purposes
				listed above. Please note that by using the Services you are consenting to the use of cookies.
			</p>
			<br/>
			<h2>Why do we collect your data</h2>
			<p className="text-privacy">
				{'\u2022'} To deliver the products and services that you have requested, manage your account and provide you
				with users support.{<br/>}
				{'\u2022'} To help better understand how our Services are being used and to help tailor content and advertising
				across the Services.{<br/>}
				{'\u2022'} To better design the Services to improve Users' experiences, both in terms of content and ease of
				use.{<br/>}
				{'\u2022'} Provide and deliver products and services you request{<br/>}
				{'\u2022'} Send you Service-related communications
			</p>
			<br/>
			<h2>Provision of advertising content</h2>
			<p className="text-privacy">
				We use third-party advertising companies to serve ads when you use Mobile Applications. To show you personalized
				advertisements in the Service these companies may use:{<br/>}
				{'\u2022'} information about your visits to any of the Websites and other websites{<br/>}
				{'\u2022'} our mobile applications and other mobile applications{<br/>}
				You have the ability to opt out of the use of your information for purposes of online third-party advertising.
				You may have the ability to manage tracking and ad preferences on your mobile device from your devices settings.
			</p>
			<br/>
			<h2>Who can see your data</h2>
			<p className="text-privacy">
				In law. We may disclose your information to third parties when obligated or permitted to do so by law and in
				order to investigate, prevent, or take action regarding suspected, or actual prohibited activities.
				We reserve the right to disclose to third parties non-personally identifiable information collected for any
				lawful purpose.
				We may transfer information, including any personally identifiable information, to a potential buyer or
				receiver.
			</p>
			<br/>
			<h2>Change or remove identification information</h2>
			<p className="text-privacy">
				You can at any time send us a request to change or delete personally identifiable information or delete/change
				your account settings.
			</p>
			<br/>
			<h2>How do we protect your data</h2>
			<p className="text-privacy">
				In order to help ensure a secure and safe player experience, we are continuously developing and implementing
				administrative, technical and physical security to protect information from unauthorized access or against loss,
				misuse or alteration.
				Note that even the best security measures cannot fully eliminate all risks. We are not responsible for third
				party circumvention of any privacy settings or security measures.
			</p>
			<br/>
			<h2>Age limits</h2>
			<p className="text-privacy">
				We do not knowingly collect or solicit personal data about or direct or target interest based advertising to
				anyone under the age of 13 or allow such persons to use our Services.
				No one under the age of 13 may provide any personal data: any data about yourself to us, including name,
				address, telephone number, or email address.
				If we learn that we have collected personal data about a child under age 13, we will delete that data.
			</p>
			<br/>
			<br/>
			<LinkAndLink name1='Contact Us' uri1='/contacts' name2='EULA' uri2='/eula'/>
			<br/>
		</header>
	</div>
}

export default PrivacyPolicy