import logo from './assets/pd-logo.png';
import './App.css';
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import ContactUs from "./pages/ContactUs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Eula from "./pages/Eula";
import React from "react";

function App() {
	const location = useLocation();

	console.log(location)

	return <div className="App">
		<div className="App-top"/>
		<img src={logo} className="App-logo" alt="logo"/>
		<Routes>
			<Route path="/" element={<ContactUs/>}/>
			<Route path="/contacts" element={<ContactUs/>}/>
			<Route path="/privacy" element={<PrivacyPolicy/>}/>
			<Route path="/eula" element={<Eula/>}/>
			<Route path="*" element={<Navigate to="/contacts" replace/>}/>
		</Routes>
		<div className="footer">
			<p>(c) 2022 Playdarium</p>
		</div>
	</div>;
}

export default App;
