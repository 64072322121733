import {useNavigate} from "react-router-dom";
import PropTypes from "prop-types";

const LinkAndLink = (props) => {
	const navigate = useNavigate()

	return <div className="center">
		{<h4 className="centerChild" onClick={() => navigate(props.uri1)}>{props.name1}</h4>}
		{<b>and</b>}
		{<h4 className="centerChild" onClick={() => navigate(props.uri2)}>{props.name2}</h4>}
	</div>
}

LinkAndLink.propTypes = {
	name1: PropTypes.string.isRequired,
	uri1: PropTypes.string.isRequired,
	name2: PropTypes.string.isRequired,
	uri2: PropTypes.string.isRequired,
}

export default LinkAndLink

