import LinkAndLink from "../Components/LinkAndLink";
import Mail from "../Components/Mail";

const ContactUs = () => {
	document.title = "Playdarium Contacts"

	return <div>
		<header className="App-header">
			<h2>Contact us</h2>
			<p className="text">
				We are committed to making interesting and high quality mobile games for you.
				We are open to everyone who is ready to contribute to the creation of truly high-quality mobile projects. If
				you have any ideas or comments that you would like to share, please write to us. We will be happy to answer
				everyone.
			</p>
			<p>Send us an email: {<Mail mail='games@playdarium.com'/>}</p>
			<br/>
			<br/>
			<br/>
			<LinkAndLink name1='Privacy Policy' uri1='/privacy' name2='EULA' uri2='/eula'/>
		</header>
	</div>
}

export default ContactUs